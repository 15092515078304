import React from "react";

function HeadLogo() {
  return (
    <div>
      <h1 className="text-3xl mb-10">Patient/Doctor Portal</h1>
    </div>
  );
}

export default HeadLogo;
