import React, { useState, useEffect } from "react";
import axios from "axios";

import PatientSidebar from "../components/dashboard/PatientSidebar";
import { Link } from "react-router-dom";
import PatientProfileDropdown from "../components/dashboard/PatientProfileDropdown";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // go to login if not logged in
  useEffect(() => {
    const getUserState = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_API + "/api/public/user-type", {
            withCredentials: true,
          })
          .then((res) => {
            const userType = res.data;
            if (userType === false) {
              navigate("/");
            }
          });
        // const userType = response.data;
      } catch (err) {
        console.error(err);
      }
    };
    getUserState();
  }, [navigate]);

  useEffect(() => {
    // get user details
    const getUserDetails = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API + "/api/protected/data/dashboard",
          {
            withCredentials: true,
          }
        );
        const userData = res.data[0];
        setUser(userData.firstName);
      } catch (err) {
        console.error(err);
      }
    };
    getUserDetails();
  }, [navigate]);
  return (
    <div>
      <div className="flex pt-40  gap-x3">
        <div className="w-4/12 ">
          <PatientSidebar />
        </div>
        <div className="w-6/12">
          <h3 className="text-3xl pt-20 ">Welcome {user}</h3>
          <h3 className="text-3xl pt-20 ">Latest Active Application</h3>
          <p>You have no current active applications submitted to SAHPRA.</p>
          <h3 className="text-3xl pt-20 ">Doctor Details</h3>
          <h3 className="text-3xl pt-20 ">Payments</h3>
          <div className="pt-3">
            {" "}
            <Link
              to="#"
              className="mb-2 inline-block text-lg bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            >
              Pay Application
            </Link>
            <br></br>
            <Link
              to="#"
              className="inline-block text-lg bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            >
              Pay for Medicines and Repeats
            </Link>
          </div>
        </div>
        <div className="w-2/12 ">
          <PatientProfileDropdown />{" "}
          <div className="text-left">
            <h3 className="text-3xl pt-10 pb-3 ">Resource Portal</h3>
            <Link
              to="#"
              className="block mb-2 text-lg hover:font-semibold transition-all"
            >
              Invite a friend
            </Link>
            <Link
              to="#"
              className="block mb-2 text-lg hover:font-semibold transition-all"
            >
              Strainprint e-journal
            </Link>
            <Link
              to="#"
              className="block mb-2 text-lg hover:font-semibold transition-all"
            >
              Educational Content
            </Link>
            <Link
              to="#"
              className="block mb-2 text-lg hover:font-semibold transition-all"
            >
              Book a Consult
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
