import React from "react";
import HeadLogo from "./HeadLogo";
function headComponent() {
  return (
    <div>
      <HeadLogo />
      <h2 className="text-xl mb-12">
        A platform where doctors and patients can connect and fulfil the
        requirements needed for medical cannabis use in South Africa.{" "}
      </h2>
    </div>
  );
}

export default headComponent;
