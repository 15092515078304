import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePickerCustom from "../global/DatePickerCustom";
import Input from "../global/Input";
import { parseISO } from "date-fns";
import Select from "../global/Select";

function PatientProfileFields() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    caregiverEmail: "",
    patientCode: "",
    title: "",
    idNumber: "",
    dateOfBirth: "",
    height: "",
    weight: "",
    gender: "",
    mobileNumber: "",
    telephoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    postal: "",
    province: "",
    age: "",
  });
  const [profileDetEdit, setProfileDetEdit] = useState(true);

  const [accountDetEdit, setAccountDetEdit] = useState(true);
  const [deliveryDetEdit, setDeliveryDetEdit] = useState(true);

  const profileHandleClick = async (event) => {
    setProfileDetEdit(false);
  };
  const accountHandleClick = () => {
    setAccountDetEdit(false);
  };
  const deliveryHandleClick = () => {
    setDeliveryDetEdit(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/api/protected/data",
          {
            withCredentials: true,
          }
        );
        const {
          firstName,
          lastName,
          password,
          email,
          caregiverEmail,
          patientCode,
          title,
          idNumber,
          dateOfBirth,
          height,
          weight,
          gender,
          mobileNumber,
          telephoneNumber,
          address1,
          address2,
          city,
          postal,
          province,
        } = response.data[0];

        // calculate age
        const today = new Date();
        const birthDate = parseISO(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        setFormData((prevFormData) => ({
          ...prevFormData,
          firstName,
          lastName,
          password,
          email,
          caregiverEmail,
          patientCode,
          title,
          idNumber,
          dateOfBirth: dateOfBirth ? parseISO(dateOfBirth) : null, // Convert string to Date object
          height,
          weight,
          gender,
          mobileNumber,
          telephoneNumber,
          address1,
          address2,
          city,
          postal,
          province,
          age,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  // get user details
  const personalInfoHandleSubmit = async (event) => {
    event.preventDefault();

    const updatedUserData = {
      title: formData.title,
      firstName: formData.firstName,
      lastName: formData.lastName,
      idNumber: formData.idNumber,
      dateOfBirth: formData.dateOfBirth,
      gender: formData.gender,
      height: formData.height,
      weight: formData.weight,
    };
    try {
      await axios.put(
        process.env.REACT_APP_API +
          `/api/protected/data/update/profileDetGroup`,
        updatedUserData,
        {
          withCredentials: true,
        }
      );
      setProfileDetEdit(true);
    } catch (error) {
      console.log(error);
    }
  };
  const accountDetHandleSubmit = async (event) => {
    event.preventDefault();
    const updatedUserData = {
      telephoneNumber: formData.telephoneNumber,
      mobileNumber: formData.mobileNumber,
      caregiverEmail: formData.caregiverEmail,
      dateOfBirth: formData.dateOfBirth,
      // password: formData.password,
      patientCode: formData.patientCode,
    };
    try {
      await axios.put(
        process.env.REACT_APP_API +
          `/api/protected/data/update/accountDetGroup`,

        updatedUserData,
        {
          withCredentials: true,
        }
      );
      setAccountDetEdit(true);
    } catch (error) {
      console.log(error);
    }
  };
  const deliveryDetHandleSubmit = async (event) => {
    event.preventDefault();
    const updatedUserData = {
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      postal: formData.postal,
      province: formData.province,
    };
    try {
      await axios.put(
        process.env.REACT_APP_API +
          `/api/protected/data/update/deliveryDetGroup`,

        updatedUserData,
        {
          withCredentials: true,
        }
      );
      setDeliveryDetEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="pb-20">
      <div className="personal-info">
        <h3 className="text-3xl pt-20 pb-10">Personal Information</h3>
        <button
          onClick={profileHandleClick}
          className={profileDetEdit ? "" : "hidden"}
        >
          Edit
        </button>
        <div className="field-group">
          <form onSubmit={personalInfoHandleSubmit}>
            <div className="flex gap-x-2">
              <label className="font-normal ">
                Title
                <Select
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      title: event.target.value,
                    })
                  }
                  value={formData.title}
                  id="Title"
                  disabled={profileDetEdit}
                  className={
                    profileDetEdit ? "mm-disabled profile font-semibold" : ""
                  }
                >
                  <option value="Not Set">Select Title</option>

                  <option value="Dr">Dr</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Prof">Prof</option>
                </Select>
              </label>

              <br />
            </div>
            <div className="flex gap-x-2">
              <label className="font-normal w-1/2 pb-2 ">
                First Name
                <Input
                  type="text"
                  value={formData.firstName}
                  disabled={profileDetEdit}
                  className={
                    profileDetEdit ? "mm-disabled profile font-semibold" : ""
                  }
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      firstName: event.target.value,
                    })
                  }
                />
              </label>
              <label className="font-normal w-1/2 pb-2 ">
                Last Name
                <Input
                  type="text"
                  value={formData.lastName}
                  disabled={profileDetEdit}
                  className={
                    profileDetEdit ? "mm-disabled profile font-semibold" : ""
                  }
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      lastName: event.target.value,
                    })
                  }
                />
              </label>
            </div>
            <div className="flex gap-x-2">
              <label className="font-normal w-1/2 pb-2 ">
                ID Number
                <Input
                  type="text"
                  value={formData.idNumber}
                  disabled={profileDetEdit}
                  className={
                    profileDetEdit ? "mm-disabled profile font-semibold" : ""
                  }
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      idNumber: event.target.value,
                    })
                  }
                />
              </label>
              <label className="font-normal w-1/2 pb-2 ">
                Date of Birth
                <DatePickerCustom
                  className={"mm-disabled profile font-semibold"}
                  disabled
                  selected={formData.dateOfBirth}
                  onChange={(date) =>
                    setFormData({
                      ...formData,
                      dateOfBirth: date,
                    })
                  }
                />
              </label>
            </div>
            <div className="flex gap-x-2">
              <label className="font-normal w-1/2 pb-2 ">
                Age
                <Input
                  type="text"
                  value={formData.age}
                  disabled
                  className={"mm-disabled profile font-semibold"}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      age: event.target.value,
                    })
                  }
                />
              </label>
              <label className="font-normal w-1/2 ">
                Gender
                <Select
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      gender: event.target.value,
                    })
                  }
                  id="Gender"
                  disabled={profileDetEdit}
                  value={formData.gender}
                  className={
                    profileDetEdit ? "mm-disabled profile font-semibold" : ""
                  }
                >
                  <option default value="Not Set">
                    Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
              </label>
            </div>
            <div className="flex gap-x-2">
              <label className="font-normal w-1/2 pb-2 ">
                Height (cm)
                <Input
                  type="number"
                  value={formData.height}
                  disabled={profileDetEdit}
                  className={
                    profileDetEdit ? "mm-disabled profile font-semibold" : ""
                  }
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      height: event.target.value,
                    })
                  }
                />
              </label>
              <label className="font-normal w-1/2 pb-2 ">
                Weight (kg)
                <Input
                  type="number"
                  value={formData.weight}
                  disabled={profileDetEdit}
                  className={
                    profileDetEdit ? "mm-disabled profile font-semibold" : ""
                  }
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      weight: event.target.value,
                    })
                  }
                />
              </label>
            </div>
            {profileDetEdit ? (
              ""
            ) : (
              <div className="block">
                <button
                  type="submit"
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
                >
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="account-details">
        <h3 className="text-3xl pt-20 pb-10">Account & Contact Details</h3>
        <button
          onClick={accountHandleClick}
          className={accountDetEdit ? "" : "hidden"}
        >
          Edit
        </button>
        <div className="field-group">
          <form onSubmit={accountDetHandleSubmit}>
            <label className="font-normal  pb-2 ">
              Email Address
              <Input
                type="email"
                value={formData.email}
                disabled
                className={"mm-disabled profile font-semibold"}
              />
            </label>
            <label className="font-normal  pb-2 ">
              Telephone number
              <Input
                type="text"
                value={formData.telephoneNumber}
                disabled={accountDetEdit}
                className={
                  accountDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    telephoneNumber: event.target.value,
                  })
                }
              />
            </label>

            <label className="font-normal  pb-2 ">
              Mobile number
              <Input
                type="text"
                value={formData.mobileNumber}
                disabled={accountDetEdit}
                className={
                  accountDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    mobileNumber: event.target.value,
                  })
                }
              />
            </label>
            <label className="font-normal  pb-2 ">
              Care Giver Email
              <Input
                type="text"
                value={formData.caregiverEmail}
                disabled={accountDetEdit}
                className={
                  accountDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    caregiverEmail: event.target.value,
                  })
                }
              />
            </label>

            <label className="font-normal  pb-2 ">
              Password
              <Input
                type="password"
                placeholder="●●●●●"
                disabled={accountDetEdit}
                className={
                  accountDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    password: event.target.value,
                  })
                }
              />
            </label>

            <label className="font-normal  pb-2 ">
              Patient Code
              <Input
                type="text"
                value={formData.patientCode}
                disabled={accountDetEdit}
                className={
                  accountDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    patientCode: event.target.value,
                  })
                }
              />
            </label>
            {accountDetEdit ? (
              ""
            ) : (
              <div className="block">
                <button
                  type="submit"
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
                >
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="delivery-details">
        <h3 className="text-3xl pt-20 pb-10">Delivery Details</h3>
        <button
          onClick={deliveryHandleClick}
          className={deliveryDetEdit ? "" : "hidden"}
        >
          Edit
        </button>
        <div className="field-group">
          <form onSubmit={deliveryDetHandleSubmit}>
            <label className="font-normal  pb-2 ">
              Residential Address Line 1
              <Input
                type="text"
                value={formData.address1}
                disabled={deliveryDetEdit}
                className={
                  deliveryDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    address1: event.target.value,
                  })
                }
              />
            </label>
            <label className="font-normal  pb-2 ">
              Residential Address Line 2
              <Input
                type="text"
                value={formData.address2}
                disabled={deliveryDetEdit}
                className={
                  deliveryDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    address2: event.target.value,
                  })
                }
              />
            </label>

            <label className="font-normal  pb-2 ">
              City
              <Input
                type="text"
                value={formData.city}
                disabled={deliveryDetEdit}
                className={
                  deliveryDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    city: event.target.value,
                  })
                }
              />
            </label>
            <label className="font-normal  pb-2 ">
              Postal Code
              <Input
                type="text"
                value={formData.postal}
                disabled={deliveryDetEdit}
                className={
                  deliveryDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    postal: event.target.value,
                  })
                }
              />
            </label>

            <label className="font-normal  pb-2 ">
              Province
              <Input
                type="text"
                value={formData.province}
                disabled={deliveryDetEdit}
                className={
                  deliveryDetEdit ? "mm-disabled profile font-semibold" : ""
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    province: event.target.value,
                  })
                }
              />
            </label>
            {deliveryDetEdit ? (
              ""
            ) : (
              <div className="block">
                <button
                  type="submit"
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
                >
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default PatientProfileFields;
