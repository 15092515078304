import React from "react";

import Login from "./pages/Login";
import PatientReg from "./pages/PatientRegistration";
import DoctorReg from "./pages/DoctorRegistration";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/PatientDashboard";
import PatientProfile from "./pages/PatientProfile";
import PatientApplication from "./pages/PatientApplication";
import PatientDoctors from "./pages/PatientDoctors";
import PatientContact from "./pages/PatientContact";
import PatientPayment from "./pages/PatientPayment";
import PatientRegistrationComplete from "./pages/PatientRegistrationComplete";
import ConfirmEmail from "./pages/ConfirmEmail";
import DoctorRegistrationComplete from "./pages/DoctorRegistrationComplete";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <div className="container mx-auto">
        <Routes>
          {/* login routes  */}
          <Route exact path="/" element={<Login />} />
          <Route path="/patient/register" element={<PatientReg />} />
          <Route
            path="/patient/complete-registration"
            element={<PatientRegistrationComplete />}
          />
          <Route path="/doctor/register" element={<DoctorReg />} />
          <Route
            path="/doctor/complete-registration"
            element={<DoctorRegistrationComplete />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/confirm-email/:confirmationCode"
            element={<ConfirmEmail />}
          />

          {/* patient routes  */}
          <Route path="/patient/dashboard" element={<Dashboard />} />
          <Route path="/patient/doctors" element={<PatientDoctors />} />
          <Route
            path="/patient/applications"
            element={<PatientApplication />}
          />
          <Route path="/patient/payments" element={<PatientPayment />} />
          <Route path="/patient/contact" element={<PatientContact />} />
          <Route path="/patient/profile" element={<PatientProfile />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
