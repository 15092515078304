import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function ConfirmEmail(props) {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { confirmationCode } = useParams();
  useEffect(() => {
    const confirmEmail = async () => {
      try {
        // const confirmationCode = props.match?.params?.confirmationCode;
        console.log(confirmationCode);
        if (!confirmationCode) return;
        await axios.get(`/api/confirm-email/${confirmationCode}`);
        setMessage("Your email address has been confirmed.Redirecting...");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } catch (err) {
        console.log(err);
      }
    };

    confirmEmail();
  }, [props.match?.params?.confirmationCode, navigate]);

  return (
    <div>
      <p>{message}</p>
    </div>
  );
}
export default ConfirmEmail;
