import React, { useState, useEffect } from "react";
import Input from "../global/Input";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");

  const login = async (email, password) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API + "/login",
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      );
      // const { token } = res.data;

      const { userType } = res.data;
      // console.log(token);
      return userType;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const success = await login(email, password);
    if (success) {
      if (success === "patient") {
        navigate("patient/dashboard");
      } else if (success === "doctor") {
        navigate("doctor/dashboard");
      }
    } else {
      setLoginError("Invalid email or password");
    }
  };

  // go to dashboard if logged in
  useEffect(() => {
    // Check for JWT cookie and get user details
    const getUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/api/public/user-type",
          {
            withCredentials: true,
          }
        );
        const userType = response.data;

        if (userType === "patient") {
          navigate("/patient/dashboard");
        } else if (userType === "doctor") {
          navigate("/doctor/dashboard");
        }
      } catch (err) {
        console.error(err);
      }
    };
    getUserDetails();
  }, [navigate]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex">
        <div className="w-3/4">
          <div>
            <h3 className="text-3xl mb-4">Login</h3>

            <Input
              className="w-10/12-important"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
              placeholder="Email Address"
            />
          </div>
          <div>
            <Input
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
              placeholder="Password"
            />
            {loginError && <div className="text-red-800 ">{loginError}</div>}
            <Link
              to="/forgot-password"
              className="text-xl underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit py-3 inline-block"
            >
              Lost password
            </Link>
            <p className="mb-4">
              We will provide information in response to your enquiry &
              occasional updates on related news, events and resources. You may
              unsubscribe from our communications at any time. For information,
              please review our Privacy & Cookies Policy.
            </p>
          </div>

          <button
            className="text-lg bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            type="submit"
          >
            Log In
          </button>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
