import React, { useState } from "react";
import Input from "../global/Input";
import Select from "../global/Select";
import Textarea from "../global/Textarea";
import { Link } from "react-router-dom";
import axios from "axios";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactReason: "General Query",
    message: "",
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const contactData = {
        name: formData.name,
        email: formData.email,
        contactReason: formData.contactReason,
        message: formData.message,
      };
      const body = JSON.stringify(contactData);
      await axios.post(
        process.env.REACT_APP_API + "/api/public/contact/submit",
        body,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setFormSubmit(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {formSubmit === true && <div></div>}
      {formSubmit === false && (
        <div>
          <h3 className="text-3xl pt-20 pb-10">Contact</h3>
          <form onSubmit={handleSubmit}>
            <label className="font-normal  pb-2 ">
              Name
              <Input
                placeholder="Name"
                type="text"
                value={formData.name}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    name: event.target.value,
                  })
                }
              />
            </label>
            <label className="font-normal  pb-2 ">
              Email Address
              <Input
                placeholder="Email Address"
                type="email"
                value={formData.email}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  })
                }
              />
            </label>
            <label className="font-normal ">
              Reason for contacting
              <Select
                value={formData.contactReason}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    contactReason: event.target.value,
                  })
                }
                id="contactReason"
              >
                <option default value="General Query">
                  General Query
                </option>
                <option value="Technical Support">Technical Support</option>
                <option value="Order and Delivery">Order and Delivery</option>
                <option value="Delivery Query">Delivery Query</option>
                <option value="Other">Other</option>
              </Select>
            </label>
            <label className="font-normal  pb-2 ">
              Message
              <Textarea
                placeholder="Message"
                value={formData.message}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    message: event.target.value,
                  })
                }
              />
            </label>
            <div>
              <Link
                to="tel:083 233 3009"
                className=" underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit py-3 inline-block"
              >
                083 233 3009
              </Link>{" "}
              |{" "}
              <Link
                to="mailto:hello@patient/doctor-portal.co.za"
                className=" underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit py-3 inline-block"
              >
                hello@patient/doctor-portal.co.za
              </Link>
            </div>
            <div className="block">
              <button
                type="submit"
                className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ContactForm;
