import React from "react";
import HeadLogo from "../global/HeadLogo";
import { Link, NavLink } from "react-router-dom";

function PatientSidebar() {
  return (
    <div className="flex flex-col justify-between ">
      <HeadLogo />
      <div className="patient-sidebar-top mb-60">
        <NavLink
          to="/patient/dashboard"
          className="block mb-2 text-lg hover:font-semibold transition-all dashboard-nav"
          activeClassName="active"
          {...{ activeClassName: undefined }}
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/patient/doctors"
          className="block mb-2 text-lg hover:font-semibold transition-all dashboard-nav"
          activeClassName="active"
          {...{ activeClassName: undefined }}
        >
          Doctors
        </NavLink>
        <NavLink
          to="/patient/applications"
          className="block mb-2 text-lg hover:font-semibold transition-all dashboard-nav"
          activeClassName="active"
          {...{ activeClassName: undefined }}
        >
          My Applications
        </NavLink>
        <NavLink
          to="/patient/payments"
          className="block mb-2 text-lg hover:font-semibold transition-all dashboard-nav"
          activeClassName="active"
          {...{ activeClassName: undefined }}
        >
          Payments
        </NavLink>
        <Link
          to="#"
          className="block mb-2 text-lg hover:font-semibold transition-all dashboard-nav"
          // activeClassName="active"
        >
          Online Shop
        </Link>
        <NavLink
          to="/patient/contact"
          c
          className="block mb-2 text-lg hover:font-semibold transition-all dashboard-nav"
          activeClassName="active"
          {...{ activeClassName: undefined }}
        >
          Contact
        </NavLink>
      </div>
      <div className="patient-sidebar-bottom">
        <NavLink
          to="/privacy-policy"
          className="block mb-2 text-lg hover:font-semibold transition-all dashboard-nav"
          activeClassName="active"
          {...{ activeClassName: undefined }}
        >
          Privacy Policy
        </NavLink>
        <NavLink
          to="/terms"
          className="block mb-2 text-lg hover:font-semibold transition-all dashboard-nav"
          activeClassName="active"
          {...{ activeClassName: undefined }}
        >
          Terms & Conditions
        </NavLink>
      </div>
    </div>
  );
}

export default PatientSidebar;
