import React, { useEffect } from "react";
import axios from "axios";

import PatientSidebar from "../components/dashboard/PatientSidebar";
import PatientProfileDropdown from "../components/dashboard/PatientProfileDropdown";
import { useNavigate } from "react-router-dom";
import PatientProfileFields from "../components/dashboard/PatientProfileFields";

function PatientProfile() {
  const navigate = useNavigate();
  // go to login if not logged in
  useEffect(() => {
    const getUserState = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_API + "/api/public/user-type", {
            withCredentials: true,
          })
          .then((res) => {
            const userType = res.data;
            if (userType === false) {
              navigate("/");
            }
          });
        // const userType = response.data;
      } catch (err) {
        console.error(err);
      }
    };
    getUserState();
  }, [navigate]);

  return (
    <div className="flex pt-40  gap-x3">
      <div className="w-4/12 ">
        <PatientSidebar />
      </div>
      <div className="w-6/12">
        <PatientProfileFields />
      </div>
      <div className="w-2/12 text-right">
        <PatientProfileDropdown />{" "}
      </div>
    </div>
  );
}

export default PatientProfile;
