import React, { useState, useEffect } from "react";
import Input from "../global/Input";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function PatientRegistrationForm() {
  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstName") || ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("lastName") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [consentPersonalAgreed, setConsentPersonalAgreed] = useState(false);
  const [consentIntroAgreed, setConsentIntroAgreed] = useState(false);
  const navigate = useNavigate();
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setPasswordError("");
    const validatePassword = (value) => {
      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]).{8,}$/;
      if (!passwordRegex.test(value)) {
        return "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character";
      }
      return "";
    };

    setPasswordError(validatePassword(value));
  };
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  // handle password validation
  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setPasswordError("");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // handle password validation
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    localStorage.setItem("email", email);
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);

    // to second step
    try {
      const formData = {
        email: email,
        password: password,
      };
      const body = JSON.stringify(formData);
      await axios.post(process.env.REACT_APP_API + "/public/register", body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      navigate("/patient/complete-registration", {
        state: { firstName, lastName, email, password },
      });
    } catch (err) {
      switch (err.response && err.response.status) {
        case 409:
          setEmailErrorMessage("Email already exists");
          break;

        default:
          setPasswordError("Server error?");
      }
    }
  };
  // go to dashboard if logged in
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/api/public/user-type",
          {
            withCredentials: true,
          }
        );
        const userType = response.data;

        if (userType === "patient") {
          navigate("/patient/dashboard");
        } else if (userType === "doctor") {
          navigate("/doctor/dashboard");
        }
      } catch (err) {
        console.error(err);
      }
    };
    getUserDetails();
  }, [navigate]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          First Name:
          <Input
            type="text"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            placeholder="First Name *"
            required
          />
        </label>
        <br />
        <label className="w-1/2">
          Last Name:
          <Input
            type="text"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            placeholder="Last Name *"
            required
          />
        </label>
        <br />
      </div>
      <label>
        Email Address:
        <Input
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="Email *"
          required
        />
      </label>
      {emailErrorMessage && (
        <div className="text-red-800 ">{emailErrorMessage}</div>
      )}
      <br />
      <label>
        Password:
        <Input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          required
        />
      </label>
      <br />
      <label>
        Confirm Password:
        <Input
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          placeholder="Confirm Password"
          required
        />
      </label>
      {passwordError && <div className="text-red-800 ">{passwordError}</div>}
      <br />
      <div className="mt-4">
        {" "}
        <label>
          <input
            type="checkbox"
            checked={termsAgreed}
            onChange={(event) => setTermsAgreed(event.target.checked)}
            className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          I agree to the Terms & Conditions and Privacy Policy.
        </label>
      </div>

      <div>
        {" "}
        <label>
          <input
            type="checkbox"
            checked={consentPersonalAgreed}
            onChange={(event) => setConsentPersonalAgreed(event.target.checked)}
            className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          I consent to the processing of Personal Information or Special
          Personal Information.
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={consentIntroAgreed}
            onChange={(event) => setConsentIntroAgreed(event.target.checked)}
            className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          I consent to IntroCann’s marketing terms.
        </label>
      </div>
      <br />
      <button
        type="submit"
        disabled={!termsAgreed || !consentPersonalAgreed}
        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
      >
        Register
      </button>
    </form>
  );
}

export default PatientRegistrationForm;
