import React, { useState, useEffect } from "react";
import Input from "../global/Input";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    // TODO: Implement login forgot password
  };
  // go to dashboard if logged in
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/api/public/user-type",
          {
            withCredentials: true,
          }
        );
        const userType = response.data;

        if (userType === "patient") {
          navigate("/patient/dashboard");
        } else if (userType === "doctor") {
          navigate("/doctor/dashboard");
        }
      } catch (err) {
        console.error(err);
      }
    };
    getUserDetails();
  }, [navigate]);
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex">
        <div className="w-3/4">
          <div>
            <label>
              Enter the email address you’re using for your account below and we
              will send you a password reset link.
            </label>
            <Input
              className="mt-4"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
              placeholder="Email Address"
            />
            <Link
              to="/login"
              className="text-xl underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit py-3 inline-block"
            >
              Back to Login
            </Link>
            <p className="mb-4">
              We will provide information in response to your enquiry &
              occasional updates on related news, events and resources. You may
              unsubscribe from our communications at any time. For information,
              please review our Privacy & Cookies Policy.
            </p>
          </div>

          <button
            className="text-lg bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            type="submit"
          >
            Reset password
          </button>
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
