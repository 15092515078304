import React from "react";
import { Link } from "react-router-dom";
import HeadComponent from "../components/global/HeadComponent";
import PatientRegistrationForm from "../components/Login-Registration/PatientRegistrationForm";

const PatientRegistration = () => {
  return (
    <div className="flex  justify-center pt-40   gap-x-20">
      <div className="w-7/12">
        <HeadComponent />
        <h3 className="text-3xl mb-8">Patient Registration</h3>
        <PatientRegistrationForm />
      </div>
      <div className="w-5/12 flex flex-col   relative">
        <div className="absolute right-0 top-0">
          {" "}
          <Link
            to="/"
            className=" bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
          >
            Back
          </Link>
        </div>
        <div className="pt-20">
          <div className="w-8/12 mb-10">
            <h3 className="text-3xl mb-8">Already have an account?</h3>

            <Link
              to="/"
              className="text-lg bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientRegistration;
