import React from "react";
import DoctorRegistrationFormComplete from "../components/Login-Registration/DoctorRegistrationFormComplete";
import HeadComponent from "../components/global/HeadComponent";
import { Link } from "react-router-dom";

function DoctorRegistrationComplete() {
  return (
    <div className="flex  justify-center py-40   gap-x-3">
      <div className="w-1/2">
        <HeadComponent />
        <DoctorRegistrationFormComplete />
      </div>
      <div className="w-1/2 flex flex-col relative">
        <div className="absolute right-0 top-0">
          {" "}
          <Link
            to="/doctor/register"
            className=" bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
          >
            Back
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DoctorRegistrationComplete;
