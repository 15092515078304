import React, { useState, useEffect } from "react";
import Input from "../global/Input";
import Select from "../global/Select";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function DoctorRegistrationFormComplete() {
  const navigate = useNavigate();
  const location = useLocation();
  const { firstName, lastName, password, email } = location.state || "";
  const [title, setTitle] = useState("Not Set");
  const [idType, setIdType] = useState("ID Number");
  const [idNumber, setIdNumber] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [province, setProvince] = useState("");
  const [facility, setFacility] = useState("");
  const [practiceNumber, setPracticeNumber] = useState("");
  const [HPCSAregNumber, setHPCSAregNumber] = useState("");
  const [regQuali, setRegQuali] = useState("");
  const [regSpeciality, setRegSpeciality] = useState("");
  const [isDispensing, setIsDispensing] = useState(false);
  const [dispensingNumber, setDispensingNumber] = useState("");
  const [dispensingAddress1, setDispensingAddress1] = useState("");
  const [dispensingAddress2, setDispensingAddress2] = useState("");
  const [dispensingCity, setDispensingCity] = useState("");
  const [dispensingPostal, setDispensingPostal] = useState("");
  const [dispensingProvince, setDispensingProvince] = useState("");
  const [regQualiOther, setRegQualiOther] = useState("");

  // ckeck if loggged in
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/api/public/user-type",
          {
            withCredentials: true,
          }
        );
        const userType = response.data;

        if (userType === "patient") {
          return navigate("/patient/dashboard");
        } else if (userType === "doctor") {
          return navigate("/doctor/dashboard");
        }
      } catch (err) {
        console.error(err);
      }
    };
    getUserDetails();
  }, [navigate]);
  // restrict direct access to step 2
  useEffect(() => {
    if (location.state === null) {
      return navigate("/");
    }
  }, [navigate, location.state]);

  // handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    //   try {
    //     const formData = {
    //       firstName: firstName,
    //       lastName: lastName,
    //       password: password,
    //       email: email,
    //       title: title,
    //       idNumber: idNumber,
    //       dateOfBirth: dateOfBirth,
    //       height: height,
    //       weight: weight,
    //       gender: gender,
    //       mobileNumber: mobileNumber,
    //       telephoneNumber: telephoneNumber,
    //       address1: address1,
    //       address2: address2,
    //       city: city,
    //       postal: postal,
    //       province: province,
    //       caregiverEmail: "Not Set",
    //       patientCode: "Not Set",
    //     };
    //     const body = JSON.stringify(formData);
    //     await axios.post(
    //       process.env.REACT_APP_API + "/api/public/doctor/register",
    //       body,
    //       {
    //         withCredentials: true,
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     navigate("/doctor/dashboard");
    //   } catch (err) {
    //     console.error(err.response.data);
    //   }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="doctor-reg-basic-info">
        <h3 className="text-3xl mb-8">Personal Information</h3>
        <div className="flex gap-x-2">
          <label className="block">
            Title
            <Select
              onChange={(event) => setTitle(event.target.value)}
              value={title}
              id="Title"
            >
              <option default value="Not Set">
                Select Title
              </option>

              <option value="Dr">Dr</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Prof">Prof</option>
            </Select>
          </label>
          <br />
        </div>
        <div className="flex gap-x-2">
          <label className="w-1/2 pb-2">
            First Name
            <Input
              type="text"
              value={firstName}
              placeholder="First Name *"
              readOnly
              disabled
              className={"mm-disabled"}
            />
          </label>
          <br />
          <label className="w-1/2">
            Last Name
            <Input
              type="text"
              value={lastName}
              placeholder="Last Name *"
              disabled
              className={"mm-disabled"}
            />
          </label>
          <br />
        </div>

        <label className="block">
          ID Type
          <Select
            onChange={(event) => setIdType(event.target.value)}
            value={idType}
            id="Title"
          >
            <option default value="ID Number">
              ID Number
            </option>

            <option value="Passport Number">Passport Number</option>
          </Select>
        </label>
        <label>
          {idType}
          <Input
            type="text"
            value={idNumber}
            placeholder={idType}
            onChange={(event) => setIdNumber(event.target.value)}
            required
          />
        </label>
        <div className="flex gap-x-2">
          <label className="w-1/2 pb-2">
            Email Address
            <Input
              type="email"
              value={email}
              onChange={(event) => setTitle(event.target.value)}
              readOnly
              disabled
              className={"mm-disabled"}
            />
          </label>
          <br />
          <label className="w-1/2 pb-2">
            Mobile Number
            <Input
              type="text"
              value={mobileNumber}
              onChange={(event) => setMobileNumber(event.target.value)}
              placeholder="Mobile Number"
              required
            />
          </label>
        </div>

        <label className="w-1/2 pb-2">
          Office Telephone Number
          <Input
            type="text"
            value={telephoneNumber}
            onChange={(event) => setTelephoneNumber(event.target.value)}
            placeholder="Office Telephone Number"
            required
          />
        </label>
        <br />
      </div>
      <div className="doctor-reg-practice-info mt-16">
        <h3 className="text-3xl mb-8">Professional & Practice Information</h3>
        <label className="w-1/2 pb-2">
          Treating Facility or Private Practice Name
          <Input
            type="text"
            value={facility}
            onChange={(event) => setFacility(event.target.value)}
            placeholder=" Treating Facility or Private Practice Name"
            required
          />
        </label>
      </div>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          Practice Number
          <Input
            type="text"
            value={practiceNumber}
            onChange={(event) => setPracticeNumber(event.target.value)}
            placeholder="Practice Number"
          />
        </label>
        <br />
        <label className="w-1/2 pb-2">
          HPCSA Registration Number
          <Input
            type="text"
            value={HPCSAregNumber}
            onChange={(event) => setHPCSAregNumber(event.target.value)}
            placeholder="HPCSA Registration Number"
            required
          />
        </label>
      </div>
      <div className="flex gap-x-2">
        <label className="block">
          Registered Qualifications
          <Select
            onChange={(event) => setRegQuali(event.target.value)}
            value={regQuali}
            id="regQuali"
          >
            <option default value="Medical Doctor (MBChB degree)">
              Medical Doctor (MBChB degree)
            </option>

            <option value="Specialist (MBChB with one of the specialist degrees">
              Specialist (MBChB with one of the specialist degrees)
            </option>
            <option value="Other">Other</option>
          </Select>
        </label>
        <br />

        <label className="block">
          Registered Speciality
          <Select
            onChange={(event) => setRegSpeciality(event.target.value)}
            value={regSpeciality}
            id="regSpeciality"
          >
            <option value="Anaesthesiologist">Anaesthesiologist </option>
            <option value="Cardiologist">Cardiologist </option>
            <option value="Endocrinologist">Endocrinologist </option>
            <option value="ENT Specialist/Otorhinolaryngologist">
              ENT Specialist/Otorhinolaryngologist{" "}
            </option>
            <option value="Dermatologist">Dermatologist </option>
            <option value="Gastroenterologist">Gastroenterologist </option>
            <option value="General Practitioner">General Practitioner </option>
            <option value="General surgeon">General surgeon </option>
            <option value="Geriatrician">Geriatrician </option>
            <option value="Gynaecologist/obstetrician">
              Gynaecologist/obstetrician{" "}
            </option>
            <option value="Haematologist">Haematologist </option>
            <option value="HIV clinician">HIV clinician </option>
            <option value="Immunologist/Allergist">
              Immunologist/Allergist{" "}
            </option>
            <option value="Infectious Disease Physician">
              Infectious Disease Physician{" "}
            </option>
            <option value="Nephrologist">Nephrologist </option>
            <option value="Neurologist">Neurologist </option>
            <option value="Oncologist">Oncologist </option>
            <option value="Ophthalmologist">Ophthalmologist </option>
            <option value="Orthopaedic surgeon">Orthopaedic surgeon </option>
            <option value="Paediatrician">Paediatrician </option>
            <option value="Specialist physician">Specialist physician </option>
            <option value="Psychiatrist">Psychiatrist </option>
            <option value="Pulmonologist">Pulmonologist </option>
            <option value="Rheumatologist">Rheumatologist </option>
            <option value="Urologist">Urologist </option>
            <option value="Vascular surgeon">Vascular surgeon </option>
            <option value="Veterinarian">Veterinarian </option>
            <option value="Other">Other </option>
          </Select>
        </label>
      </div>
      {regQuali === "Other" && (
        <label className="w-1/2 pb-2">
          Other Registered Qualifications
          <Input
            type="text"
            value={regQualiOther}
            onChange={(event) => setRegQualiOther(event.target.value)}
            placeholder=" Other Registered Qualifications"
          />
        </label>
      )}
      <label>
        Address Line 1
        <Input
          type="text"
          value={address1}
          placeholder="Residential Address Line 1 *"
          onChange={(event) => setAddress1(event.target.value)}
        />
      </label>
      <label>
        Address Line 2
        <Input
          type="text"
          value={address2}
          placeholder="Optional"
          onChange={(event) => setAddress2(event.target.value)}
        />
      </label>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          City
          <Input
            type="text"
            value={city}
            onChange={(event) => setCity(event.target.value)}
            placeholder="City *"
            required
          />
        </label>
        <br />
        <label className="w-1/2">
          Postal Code
          <Input
            type="text"
            value={postal}
            onChange={(event) => setPostal(event.target.value)}
            placeholder="Postal Code *"
          />
        </label>
      </div>
      <label>
        Province
        <Input
          type="text"
          value={province}
          placeholder="Province *"
          onChange={(event) => setProvince(event.target.value)}
          required
          className="mb-8"
        />
      </label>
      <br />

      <div className="flex flex-col items-start">
        <label>Registered as dispensing doctor</label>
        <label className="inline-flex items-center mt-2">
          <input
            type="radio"
            className="form-radio h-5 w-5 text-indigo-600"
            name="isDispensingDoctor"
            value="yes"
            checked={isDispensing}
            onChange={(event) => setIsDispensing(event.target.value === "yes")}
          />
          <span className="ml-2">Yes</span>
        </label>
        <label className="inline-flex items-center mt-2">
          <input
            type="radio"
            className="form-radio h-5 w-5 text-indigo-600"
            name="isDispensingDoctor"
            value="no"
            checked={!isDispensing}
            onChange={(event) => setIsDispensing(event.target.value === "yes")}
          />
          <span className="ml-2">No</span>
        </label>
      </div>

      {isDispensing && (
        <div className="dispensing-details">
          <label className="mt-8 block">
            Dispensing Number
            <Input
              type="text"
              value={dispensingNumber}
              placeholder="Dispensing Number *"
              onChange={(event) => setDispensingNumber(event.target.value)}
              required
              className="mb-8"
            />
          </label>
          <label>
            Dispensing Address Line 1
            <Input
              type="text"
              value={dispensingAddress1}
              placeholder="Dispensing Address Line 1*"
              onChange={(event) => setDispensingAddress1(event.target.value)}
            />
          </label>
          <label>
            Dispensing Address Line 2
            <Input
              type="text"
              value={dispensingAddress2}
              placeholder="Optional"
              onChange={(event) => setDispensingAddress2(event.target.value)}
            />
          </label>
          <div className="flex gap-x-2">
            <label className="w-1/2 pb-2">
              City
              <Input
                type="text"
                value={dispensingCity}
                onChange={(event) => setDispensingCity(event.target.value)}
                placeholder="City *"
                required
              />
            </label>
            <br />
            <label className="w-1/2">
              Postal Code
              <Input
                type="text"
                value={dispensingPostal}
                onChange={(event) => setDispensingPostal(event.target.value)}
                placeholder="Postal Code *"
              />
            </label>
          </div>
          <label>
            Province
            <Input
              type="text"
              value={dispensingProvince}
              placeholder="Province *"
              onChange={(event) => setDispensingProvince(event.target.value)}
              required
              className="mb-8"
            />
          </label>
          <br />
        </div>
      )}
      <Input type="text" value={password} readOnly hidden />

      <button
        type="submit"
        className="mt-10 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
      >
        Register
      </button>
    </form>
  );
}

export default DoctorRegistrationFormComplete;
