import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PatientProfileDropdown from "../components/dashboard/PatientProfileDropdown";
import PatientSidebar from "../components/dashboard/PatientSidebar";
import axios from "axios";

function PatientApplication() {
  const navigate = useNavigate();

  // go to login if not logged in
  useEffect(() => {
    const getUserState = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_API + "/api/public/user-type", {
            withCredentials: true,
          })
          .then((res) => {
            const userType = res.data;
            if (userType === false) {
              navigate("/");
            }
          });
        // const userType = response.data;
      } catch (err) {
        console.error(err);
      }
    };
    getUserState();
  }, [navigate]);

  return (
    <div>
      <div className="flex pt-40  gap-x3">
        <div className="w-4/12 ">
          <PatientSidebar />
        </div>
        <div className="w-6/12 pr-20">
          <h3 class="text-3xl pt-20 pb-3">Applications</h3>
          <p>
            You don’t have any submitted applications submitted to SAHPRA.
            Please check your dashboard for active applications.
          </p>
        </div>
        <div className="w-2/12 ">
          <PatientProfileDropdown />{" "}
          <div className="text-left">
            <h3 className="text-3xl pt-10 pb-3 ">Resource Portal</h3>
            <Link
              to="#"
              className="block mb-2 text-lg hover:font-semibold transition-all"
            >
              Invite a friend
            </Link>
            <Link
              to="#"
              className="block mb-2 text-lg hover:font-semibold transition-all"
            >
              Strainprint e-journal
            </Link>
            <Link
              to="#"
              className="block mb-2 text-lg hover:font-semibold transition-all"
            >
              Educational Content
            </Link>
            <Link
              to="#"
              className="block mb-2 text-lg hover:font-semibold transition-all"
            >
              Book a Consult
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientApplication;
