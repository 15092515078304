import React, { useState, useEffect } from "react";
import Input from "../global/Input";
import Select from "../global/Select";
import { useLocation } from "react-router-dom";

import DatePickerCustom from "../global/DatePickerCustom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function PatientRegistrationFormComplete() {
  const navigate = useNavigate();
  const location = useLocation();

  const { firstName, lastName, password, email } = location.state || "";

  const [title, setTitle] = useState("Not Set");
  const [idNumber, setIdNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("Not Set");
  const [mobileNumber, setMobileNumber] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [province, setProvince] = useState("");

  // ckeck if loggged in
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/api/public/user-type",
          {
            withCredentials: true,
          }
        );
        const userType = response.data;

        if (userType === "patient") {
          return navigate("/patient/dashboard");
        } else if (userType === "doctor") {
          return navigate("/doctor/dashboard");
        }
      } catch (err) {
        console.error(err);
      }
    };
    getUserDetails();
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = {
        firstName: firstName,
        lastName: lastName,
        password: password,
        email: email,
        title: title,
        idNumber: idNumber,
        dateOfBirth: dateOfBirth,
        height: height,
        weight: weight,
        gender: gender,
        mobileNumber: mobileNumber,
        telephoneNumber: telephoneNumber,
        address1: address1,
        address2: address2,
        city: city,
        postal: postal,
        province: province,
        caregiverEmail: "Not Set",
        patientCode: "Not Set",
      };
      const body = JSON.stringify(formData);
      await axios.post(
        process.env.REACT_APP_API + "/api/public/patient/register",
        body,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/patient/dashboard");
    } catch (err) {
      console.error(err.response.data);
    }
  };
  // restrict direct access to step 2
  useEffect(() => {
    if (location.state === null) {
      return navigate("/");
    }
  }, [navigate, location.state]);

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="text-3xl mb-8">Personal Information</h3>
      <div className="flex gap-x-2">
        <label className="block">
          Title
          <Select
            onChange={(event) => setTitle(event.target.value)}
            value={title}
            id="Title"
          >
            <option default value="Not Set">
              Select Title
            </option>

            <option value="Dr">Dr</option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Ms">Ms</option>
            <option value="Prof">Prof</option>
          </Select>
        </label>
        <br />
      </div>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          First Name
          <Input
            type="text"
            value={firstName}
            placeholder="First Name *"
            readOnly
            disabled
            className={"mm-disabled"}
          />
        </label>
        <br />
        <label className="w-1/2">
          Last Name
          <Input
            type="text"
            value={lastName}
            placeholder="Last Name *"
            disabled
            className={"mm-disabled"}
          />
        </label>
        <br />
      </div>

      <label>
        ID Number
        <Input
          type="text"
          value={idNumber}
          placeholder="ID Number *"
          onChange={(event) => setIdNumber(event.target.value)}
          required
        />
      </label>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          Date of Birth
          <DatePickerCustom
            selected={dateOfBirth}
            onChange={(date) => setDateOfBirth(date)}
            placeholderText="Date of Birth"
          />
        </label>

        <br />
        <label className="w-1/2">
          Height
          <Input
            type="number"
            value={height}
            onChange={(event) => setHeight(event.target.value)}
            placeholder="Height (in cm)"
          />
        </label>
        <br />
      </div>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          Weight
          <Input
            type="number"
            value={weight}
            onChange={(event) => setWeight(event.target.value)}
            placeholder="Weight (in kg's)"
            required
          />
        </label>
        <br />
        <label className="w-1/2">
          Gender
          <Select
            onChange={(event) => setGender(event.target.value)}
            id="Gender"
            value={gender}
          >
            <option default value="Not Set">
              Gender
            </option>
            <option value="Male">Male</option>
            <option value="Dr">Female</option>
          </Select>
        </label>
        <br />
      </div>
      <br />

      <h3 className="text-3xl mb-8 mt-16">Account & Contact Details</h3>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          Email Address
          <Input
            type="email"
            value={email}
            onChange={(event) => setTitle(event.target.value)}
            readOnly
            disabled
            className={"mm-disabled"}
          />
        </label>
        <br />
      </div>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          Telephone Number
          <Input
            type="text"
            value={telephoneNumber}
            onChange={(event) => setTelephoneNumber(event.target.value)}
            placeholder="Telephone Number *"
          />
        </label>
        <br />
        <label className="w-1/2">
          Mobile Number
          <Input
            type="text"
            value={mobileNumber}
            onChange={(event) => setMobileNumber(event.target.value)}
            placeholder="Mobile Number *"
          />
        </label>
      </div>

      <h3 className="text-3xl mb-8 mt-16">Delivery Details</h3>
      <label>
        Residential Address Line 1 *
        <Input
          type="text"
          value={address1}
          placeholder="Residential Address Line 1 *"
          onChange={(event) => setAddress1(event.target.value)}
        />
      </label>
      <label>
        Residential Address Line 2 *
        <Input
          type="text"
          value={address2}
          placeholder="Optional"
          onChange={(event) => setAddress2(event.target.value)}
        />
      </label>
      <div className="flex gap-x-2">
        <label className="w-1/2 pb-2">
          City
          <Input
            type="text"
            value={city}
            onChange={(event) => setCity(event.target.value)}
            placeholder="City *"
            required
          />
        </label>
        <br />
        <label className="w-1/2">
          Postal Code
          <Input
            type="text"
            value={postal}
            onChange={(event) => setPostal(event.target.value)}
            placeholder="Postal Code *"
          />
        </label>
      </div>
      <label>
        Province
        <Input
          type="text"
          value={province}
          placeholder="Province *"
          onChange={(event) => setProvince(event.target.value)}
          required
          className="mb-8"
        />
      </label>

      <br />

      <Input type="text" value={password} readOnly hidden />

      <button
        type="submit"
        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
      >
        Register
      </button>
    </form>
  );
}

export default PatientRegistrationFormComplete;
