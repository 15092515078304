import React from "react";
import LoginForm from "../components/Login-Registration/LoginForm";
import HeadComponent from "../components/global/HeadComponent";
import { Link } from "react-router-dom";

const login = () => {
  return (
    <div className="flex  justify-center pt-40 h-screen  gap-x-3">
      <div className="w-1/2">
        <HeadComponent />
        <LoginForm />
      </div>
      <div className="w-1/2 flex flex-col   relative">
        <div className="absolute right-0 top-0">
          {" "}
          <Link
            to="/"
            className=" bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
          >
            Back
          </Link>
        </div>
        <div className="pt-20">
          <div className="w-8/12 mb-10">
            <h3 className="text-3xl mb-4">Patient Registration</h3>
            <p className="mb-8">
              Register as a patient. Find a doctor in your area and access
              medical cannabis educational material.
            </p>
            <Link
              to="/patient/register"
              className="text-lg bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            >
              Register
            </Link>
          </div>
          <div className="w-8/12">
            <h3 className="text-3xl mb-4">Doctor Registration</h3>
            <p className="mb-8">
              Register as a patient. Find a doctor in your area and access
              medical cannabis educational material.
            </p>

            <Link
              to="/doctor/register"
              className="text-lg bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            >
              Register
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default login;
